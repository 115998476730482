import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styles from './styles.module.css';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';

const picture = require('./assets/picture.png');
const build = require('./assets/Long_term_info_value_body_build_icon.png');
const lev = require('./assets/Long_term_info_value_body_lev_icon.png');
const efi = require('./assets/Long_term_info_value_body_efi_icon.png');
const planned = require('./assets/Long_term_info_value_body_planned_icon.png');
const frame = require('./assets/Short_fin_info_products_project_pitcure.png');

const LongTermInfo = (props: any) => {
  return (
    <div>
      <div className={styles.pageTop}>
        <img src={picture} className={styles.pageTopImage} />
        <Container>
          <Row className='justify-content-end'>
            <Col className={styles.pageTopCol} md={6} sm={8}>
              <h1 className={styles.pageTopTitle}>{t(props.labels, 'Long_term_info_top_title', 'heb')}</h1>
              <p className={styles.pageTopText}>{t(props.labels, 'Long_term_info_top_body', 'heb')}</p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className={styles.advanceBodyContainer}>
        <Row>
          <Col sm={3} className={styles.info_col}>
            <div className={styles.info_up}>{t(props.labels, 'Long_term_info_value_work_up', 'heb')}</div>
            <div className={styles.info_down}>{t(props.labels, 'Long_term_info_value_work_down', 'heb')}</div>
          </Col>
          <Col sm={3} className={styles.info_col}>
            <div className={styles.info_up}>{t(props.labels, 'Long_term_info_value_upto_timewire_up', 'heb')}</div>
            <div className={styles.info_down}>{t(props.labels, 'Long_term_info_value_timewire_down', 'heb')}</div>
          </Col>
          <Col sm={3} className={styles.info_col}>
            <div className={styles.info_up}>{t(props.labels, 'Long_term_info_value_interest_up', 'heb')}</div>
            <div className={styles.info_down}>{t(props.labels, 'Long_term_info_value_interest_down', 'heb')}</div>
          </Col>
          <Col sm={3} className={styles.info_col}>
            <div className={styles.info_up}>{t(props.labels, 'Long_term_info_value_upto_up', 'heb')}</div>
            <div className={styles.info_down}>{t(props.labels, 'Long_term_info_value_upto_down', 'heb')}</div>
          </Col>
        </Row>

        <Row className={styles.bodyTitle}>
          <Col md={12}>{t(props.labels, 'Long_term_info_value_body_withyou_title', 'heb')}</Col>
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginBottom: 30,
          }}
        >
          <Col md={12}>{t(props.labels, 'Long_term_info_value_body_withyou_body', 'heb')}</Col>
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginBottom: 40,
          }}
        >
          <Col md={12}>{t(props.labels, 'Long_term_info_value_body_withyou_body2', 'heb')}</Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row className='mb-5'>
              <Col md={8}>
                <b className={styles.advanceBodyTitle}>
                  {t(props.labels, 'Long_term_info_value_body_build_title', 'heb')}
                </b>
                <p className={styles.advanceBodyText}>
                  {t(props.labels, 'Long_term_info_value_body_build_body', 'heb')}
                </p>
              </Col>
              <Col md={4}>
                <img className={styles.fundingProcessImage} src={build} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className='mb-5'>
              <Col md={8}>
                <b className={styles.advanceBodyTitle}>
                  {t(props.labels, 'Long_term_info_value_body_lev_title', 'heb')}
                </b>
                <p className={styles.advanceBodyText}>{t(props.labels, 'Long_term_info_value_body_lev_body', 'heb')}</p>
              </Col>
              <Col md={4}>
                <img className={styles.fundingProcessImage} src={lev} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row className='mb-5'>
              <Col md={8}>
                <b className={styles.advanceBodyTitle}>
                  {t(props.labels, 'Long_term_info_value_body_efi_title', 'heb')}
                </b>
                <p className={styles.advanceBodyText}>{t(props.labels, 'Long_term_info_value_body_efi_body', 'heb')}</p>
              </Col>
              <Col md={4}>
                <img className={styles.fundingProcessImage} src={efi} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className='mb-5'>
              <Col md={8}>
                <b className={styles.advanceBodyTitle}>
                  {t(props.labels, 'Long_term_info_value_body_planned_title', 'heb')}
                </b>
                <p className={styles.advanceBodyText}>
                  {t(props.labels, 'Long_term_info_value_body_planned_body', 'heb')}
                </p>
              </Col>
              <Col md={4}>
                <img className={styles.fundingProcessImage} src={planned} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: 60,
            direction: 'rtl',
            textAlign: 'right',
            fontWeight: 'bold',
            fontSize: 26,
            marginRight: 55,
            marginBottom: 20,
          }}
        >
          {t(props.labels, 'Long_term_info_products_title', 'heb')}
        </Row>
        <Row
          style={{
            textAlign: 'right',
            direction: 'rtl',
            marginRight: -10,
          }}
        >
          <Col>
            <img src={frame} width={335} alt='' />
            <div className={styles.frame_text}>{t(props.labels, 'Long_fin_info_products_food_text', 'heb')}</div>
          </Col>
          <Col>
            <img src={frame} width={335} alt='' />
            <div className={styles.frame_text}>{t(props.labels, 'Long_fin_info_products_project_text', 'heb')}</div>
          </Col>
          <br />
        </Row>
      </Container>
      <div className={styles.bottomImageDiv}></div>
    </div>
  );
};

export default LongTermInfo;
